import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Bio from '../components/bio'
import Layout from '../components/layout'
import { rhythm } from '../utils/typography'

class PostsIndex extends React.Component {
	render() {
		// console.log("props", this.props)
		const pagePosts = []
		const siteTitle = get(this, 'props.data.site.siteMetadata.title')
		const posts = get(this, 'props.data.posts.edges')
		posts
			.filter(post => post.node.path !== '/404/')
			.forEach(post => {
				pagePosts.push(
					<li
						key={post.node.frontmatter.path}
						style={{
							marginBottom: rhythm(1 / 4),
						}}
					>
						<Link
							style={{ boxShadow: 'none' }}
							to={post.node.frontmatter.path}
						>
							{post.node.frontmatter.title}
						</Link>
						&nbsp;:&nbsp;
						<span
							dangerouslySetInnerHTML={{
								__html: post.node.excerpt,
							}}
							style={{ p: { display: 'inline' } }}
						/>
					</li>
				)
			})

		return (
			<Layout location={this.props.location} title={siteTitle}>

				<Helmet
					title={get(this, 'props.data.site.siteMetadata.title')}
				/>
				<Bio />
				<div>
					<h2>Tous les articles</h2>
					<ul>{pagePosts}</ul>
				</div>
			</Layout>
		)
	}
}

export default PostsIndex

export const pageQuery = graphql`
    query AllPostsQuery {
        site {
            siteMetadata {
                title
            }
        }
        posts: allMarkdownRemark(
            filter: {
                frontmatter: { draft: { ne: true }, layout: { eq: "Post" } }
            }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    excerpt
					frontmatter {
						path
						title
						date(locale: "fr-FR", formatString: "DD/MM/YYYY")
					}
                }
            }
        }
    }
`
